/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
// tslint:disable: max-line-length
import { format, isValid, max, min, parse, parseISO } from 'date-fns';

import { ContractDoc } from '../../schema/1/schema-billing';
import { StartEndDate } from '../../schema/1/schema-common';

export async function sleep(ms: number) {
  await new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * 입력 과정 중에 자동으로 -를 붙여준다.
 * util.ts에 있는 noramlizeTel과는 쓰임이 다르다.
 */
export function normalizingTel(telNo: string) {
  // 숫자 이외에는 모두 제외한다.
  telNo = telNo.replace(/[^0-9]/g, '');

  if (telNo[0] !== '0') {
    return '';
  }
  // 2번째 숫자가 허용되지 않는 숫자라면 거부
  if (telNo[1] !== '1' && telNo[1] !== '2' && telNo[1] !== '5' && telNo[1] !== '7') {
    return telNo[0];
  }

  if (telNo.match(/^010|050|070|011/)) {
    // 국번이 0이나 1로 시작하지 않는다.
    if (telNo[3] === '0' || telNo[3] === '1') {
      return telNo.substr(0, 3);
    }

    if (telNo.length === 12) {
      return `${telNo.substr(0, 4)}-${telNo.substr(4, 4)}-${telNo.substr(8, 4)}`;
    } else if (telNo.length === 10) {
      return `${telNo.substr(0, 3)}-${telNo.substr(3, 3)}-${telNo.substr(6, 4)}`;
    } else if (telNo.length > 7) {
      return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}-${telNo.substr(7, 4)}`;
    } else if (telNo.length > 3) {
      return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}`;
    } else {
      return telNo;
    }
  } else { // 02
    // 국번이 0이나 1로 시작하지 않는다.
    if (telNo[2] === '0' || telNo[2] === '1') {
      return telNo.substr(0, 2);
    }

    if (telNo.length > 9) {
      return `${telNo.substr(0, 2)}-${telNo.substr(2, 4)}-${telNo.substr(6, 4)}`;
    } else if (telNo.length > 5) {
      return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}-${telNo.substr(5, 4)}`;
    } else if (telNo.length > 2) {
      return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}`;
    } else {
      return telNo;
    }
  }
}

/**
 * - 를 삽입한다.
 */
export function normalizeTel(telNo: string) {
  if (telNo == null || telNo === '') {
    return '번호없음';
  }
  // 숫자 이외에는 모두 제외한다.
  telNo = telNo.replace(/[^0-9]/g, '');

  // 2018-11-15 부터는 050으로 변환해서 FS에 저장하기 때문에 불펼요할 수 있다.
  telNo = telNo.replace(/^090/, '050');

  // 010- , 070-
  let matches = telNo.match(/^(0[17]0)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050은 4자리 식별번호를 사용하지만 3자리가 익숙하니 12자리가 아닌 경우에는 050에서 끊어준다.
  // 050-AAA?-BBBB
  matches = telNo.match(/^(050)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050X-AAAA-BBBB
  matches = telNo.match(/^(050.)(.{4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(02)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(0..)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  return telNo;
}

/**
 * 금액 형식으로 만든다.
 */
export function normalizeCurrency(value: string) {
  // 아직 숫자가 들어오기 전 부호만 있는 상태
  if (value === '-') {
    return value;
  }

  // 숫자 이외에는 모두 제외한다.
  // 음수일 때 맨 앞 부호만 유지
  const isPositive = value.startsWith('-') ? false : true;
  value = `${isPositive ? '' : '-'}${value.replace(/[^0-9]/g, '')}`;

  // 빈칸 무효
  if (value === '') {
    return '';
  }

  return new Intl.NumberFormat().format(parseInt(value, 10));
}

/**
 * 사업자 번호 형식을 만든다.
 */
export function normalizeBusinessNumber(businessNumber = '', dynamic = false) {
  // 1. 숫자 이외에는 모두 제외한다.
  // 2. 3-2-5로 분리한다.
  // 10자리가 되지 않더라도 match 된다.
  // 동적으로 변환하는 경우는 10자리가 넘는 숫자는 버리고 그렇지 않으면 자르지 않고 뒤에 붙인다.
  const [, ...groups] = businessNumber.replace(/[^0-9]/g, '').match(`^(\\d{0,3})(\\d{0,2})(\\d${dynamic ? '{0,5}' : '*'})`);
  return groups.filter(group => group !== '').join('-');
}

/**
 * '고스트키친 삼성점 04호'를 '삼성점 04호'로 변환한다.
 */
export function trimOrganization(text: string) {
  if (typeof text === 'string') {
    return text.replace(/^고스트키친 /, '');
  }
  return '??';
}

/**
 * '고스트키친 삼성점 04호'를 '04호'로 변환한다.
 */
export function trimSite(text: string) {
  if (typeof text === 'string') {
    return text.replace(/^고스트키친 [^\s]+\s/, '');
  }
  return '??';
}

/**
 *
 * @param dateStr  '2018-01-01T12:34:56+0900'
 */
export function weekdayKR(dateStr: string): string {
  // Safari는 +09:00은 지원해도 +0900은 지원하지 않는다.
  try {
    return format(parseISO(dateStr), 'ccc');
  } catch (err) {
    return '';
  }
}

/**
 * 여러 형태의 시간을 ISO 형식의 문자열로 변환한다.
 * date2iso.test.ts에 사용예 확인
 */
export function toDate(date: string | number | Date): Date {
  if (date instanceof Date) {
    return date;
  } else if (typeof date === 'number') {
    if (date > 9999999999) {
      // 밀리초라면
      return new Date(date);
    } else {
      // 초라면
      return new Date(date * 1000);
    }
  } else {
    // Case 0. '2019-05-03T12:08:38+0900'
    let match = date.match(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})([+-]\d{2}):?(\d{2})$/);
    if (match) {
      return parseISO(date);
    }

    // Case 1-1. '2019-05-03 12:08:38'
    // Case 1-2. '2019-05-03 12:08:38.0'
    match = date.match(/^(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})(\.0)?$/);

    if (match) {
      return parseISO(`${match[1]}T${match[2]}+09:00`);
    }

    // Case 2.
    match = date.match(/^(\d{4}\d{2}\d{2})T(\d{2}\d{2}\d{2})Z/);

    if (match) {
      return parse(`${match[1]}T${match[2]}+0000`, `yyyyMMdd'T'HHmmssXXX`, new Date());
    }

    // Case 3. 1559097490
    // 단위가 초라면 10자가 될 것이다.
    match = date.match(/^\d{10}$/);
    if (match) {
      return new Date(parseInt(date, 10) * 1000);
    }

    // Case 4. 1598409508802
    // 단위가 밀리초라면 13자가 될 것이다.
    match = date.match(/^\d{13}$/);
    if (match) {
      return new Date(parseInt(date, 10));
    }

    // Case 5. '7/1/20 오후 11:55:14'
    match = date.match(/^(\d{1,2})\/(\d{1,2})\/(\d{2}) (오후|오전) (\d{1,2}):(\d{2}:\d{2})/);
    if (match) {
      const padZero = (str: string) => str.padStart(2, '0');
      const time = match[4] === '오전' ? `${padZero(match[5])}:${match[6]}`
        : `${Number(match[5]) === 12 ? 12 : Number(match[5]) + 12}:${match[6]}`;

      const dateString = `20${match[3]}-${padZero(match[1])}-${padZero(match[2])}T${time}+0900`;
      const result = parseISO(dateString);
      if (!isValid(result)) {
        throw new Error(`date:|${date}|, dateString:|${dateString}| 처리 중에 예외 발생`);
      }

      return result;
    }

    // Case 6-1. '2020-07-20 오후 11:55:14'
    // Case 6-2. '2020/07/20 오후 11:55:14'
    match = date.match(/^(\d{4})[-/](\d{2})[-/](\d{2}) (오후|오전) (\d{1,2}):(\d{2}:\d{2})/);
    if (match) {
      const padZero = (str: string) => str.padStart(2, '0');
      const time = match[4] === '오전' ? `${padZero(match[5])}:${match[6]}`
        : `${Number(match[5]) === 12 ? 12 : Number(match[5]) + 12}:${match[6]}`;

      const dateString = `${match[1]}-${match[2]}-${match[3]}T${time}+0900`;
      const result = parseISO(dateString);
      if (!isValid(result)) {
        throw new Error(`date:|${date}|, dateString:|${dateString}| 처리 중에 예외 발생`);
      }
      return result;
    }
  }

  throw TypeError(`Unexpected date format : ${date}`);
}

/**
 * 두 시각 차이를 계산해서 M:ss 형식으로 변환한다.
 * timestamp2 - timestamp1
 */
export function diffTime(timestamp1: string | number | Date, timestamp2: string | number | Date, round?: boolean) {
  if (round == null) {
    round = false;
  }

  const ret = {
    m: 0,
    s: 0,
    sStr: '00'
  };

  if (timestamp1 == null || timestamp2 == null) {
    return ret;
  }

  // Safari는 +09:00은 지원해도 +0900은 지원하지 않는다.
  const date1 = toDate(timestamp1).getTime();
  const date2 = toDate(timestamp2).getTime();

  const diffSec = Math.floor((date2 - date1) / 1000);

  let m = Math.floor(diffSec / 60);
  let s = diffSec % 60;

  if (round && s > 50) {
    s = 0;
    m += 1;
  }

  const sStr = s < 10 ? `0${s}` : `${s}`;

  ret.m = m;
  ret.s = s;
  ret.sStr = sStr;

  return ret;
}

const diffTimestampInstances: {
  [instanceKey: string]: [number, number];
} = {};
/**
 * 실행하는 시점 간의 시간 차이를 구할 때 사용한다.
 */
export function diffTimestamp(instanceKey: string) {
  const nowMilli = Date.now();
  if (diffTimestampInstances[instanceKey] === undefined) {
    diffTimestampInstances[instanceKey] = [nowMilli, 0];
  }

  // 1. get old time
  const [oldTimestamp, oldCount] = diffTimestampInstances[instanceKey];
  // 2. calculate diffTIme
  const diffMilli = nowMilli - oldTimestamp;

  const sec = Math.floor(diffMilli / 1000);
  const milli = String(diffMilli % 1000);

  // 3. update oldTIme
  diffTimestampInstances[instanceKey] = [nowMilli, oldCount + 1];

  return `[${oldCount + 1}] ${sec}.${milli.padStart(3, '0')}`;
}

/**
 * 1 -> 'A', 2 -> 'B', ....
 */
export function numberToAlphabet(num: number) {
  return String.fromCharCode(num + 64);
}

// 청구 조회기간 표시
export function getBillingDate(billingDate: Date): StartEndDate {
  // 청구월의 전월 1일, 말일
  const monthStartDateTime = new Date(billingDate);
  const startDate = format(monthStartDateTime, 'yyyy-MM-dd');

  const monthEndDateTime = new Date(billingDate.getFullYear(), billingDate.getMonth() + 1, 0);
  const endDate = format(monthEndDateTime, 'yyyy-MM-dd');

  return { startDate, endDate };
}

// 실제 데이터 조회 기간
export function getSearchDate(billingStartDate: string, billingEndDate: string, businessStartDate: string, businessEndDate: string): StartEndDate {
  const deliveryStartDateTime = max([new Date(billingStartDate), new Date(businessStartDate)]);
  const startDate = format(deliveryStartDateTime, 'yyyy-MM-dd');

  let endDate = '';
  if (businessEndDate) {
    const deliveryEndDateTime = min([new Date(billingEndDate), new Date(businessEndDate)]);
    endDate = format(deliveryEndDateTime, 'yyyy-MM-dd');
  } else {
    endDate = format(new Date(billingEndDate), 'yyyy-MM-dd');
  }

  return { startDate, endDate };
}

// input에 대한 형식과 캘린더 월 셀렉트박스 형식
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY-MM',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM'
  }
};

// 요일 및 월 표시 형식을 한글로 표시한다.
export const MY_LOCALE = {
  monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  weekdaysMin: ['일', '월', '화', '수', '목', '금', '토']
};

/**
 * Math.max(...)의 string 버전
 *
 * maxString('2020-01-31', '2020-02-01', '2019-01-31') => '2020-02-01'
 */
export function maxString(...strs: string[]) {
  return strs.sort((a, b) => a < b ? 1 : a > b ? -1 : 0)[0];
}

/**
 * Math.min(...)의 string 버전
 *
 * maxString('2020-01-31', '2020-02-01', '2019-01-31') => '2019-01-31'
 */
export function minString(...strs: string[]) {
  return strs.sort((a, b) => a < b ? -1 : a > b ? 1 : 0)[0];
}

/**
 * 두 날짜의 기간을 계산한다.
 * inclusive: 날짜가 같으면 1
 *
 * @param fromDateString 2020-07-01
 * @param toDateString   2020-07-02
 */
export function inclusiveDiffDateString(fromDateString: string, toDateString: string) {
  const re = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
  if (re.test(fromDateString) === false || re.test(toDateString) === false) {
    throw new TypeError(`${fromDateString} 혹은 ${toDateString}의 형식이 잘 못 되었다.`);
  }

  if (fromDateString > toDateString) {
    console.warn(`[inclusiveDiffDateString] ${fromDateString}이 ${toDateString}보다 크다`);
  }

  const unixTime1 = new Date(fromDateString).getTime();
  const unixTime2 = new Date(toDateString).getTime();
  const days = (unixTime2 > unixTime1 ? (unixTime2 - unixTime1) : (unixTime1 - unixTime2)) / (24 * 3600 * 1000) + 1;

  return days;
}

/**
 * 주어진 달은 몇 일인가?
 *
 * @param month 음수도 가능하다. 2021, 0이면 2020, 12와 같다.
 */
export function daysOfMonth(year: number, month: number) {
  const thisMonthFirstDay = new Date(year, month - 1, 1).getTime();
  const nextMonthFirstDay = new Date(year, month, 1).getTime();

  return (nextMonthFirstDay - thisMonthFirstDay) / (24 * 60 * 60 * 1000);
}

/**
 * 청구월 범위와 계약기간를 비교해서 공통되는 기간을 계산한다.
 * 직전 연장 계약이 있는 경우에는 계약 기간을 확장해서 계산한다.
 *
 * @param billingYear 청구년
 * @param billingMonth 청구월. 임대료, 관리비는 선불이기 때문에 8월 10일 청구일이 속하는 달인 8월 1일 ~ 31일이 청구대상기간이 된다.
 * @param offsetMonth 당월: 0, 전월: -1
 */
export function billingPeriod(billingYear: number, billingMonth: number, offsetMonth: number, mainContract: ContractDoc) {
  // new Date(year, month, day, hour, minute, seconds)는 localtime(+09:00)이 적용된다.
  const billingStartTime = new Date(billingYear, billingMonth - 1 + (offsetMonth), 1, 0, 0, 0).getTime();
  const billingEndTime = new Date(billingYear, billingMonth + offsetMonth, 0, 23, 59, 59).getTime();

  const billingStartDateString = format(new Date(billingStartTime), 'yyyy-MM-dd');
  const billingEndDateString = format(new Date(billingEndTime), 'yyyy-MM-dd');

  const ret = intersectionPeriod([
    {
      startDateString: billingStartDateString,
      endDateString: billingEndDateString
    },
    {
      startDateString: mainContract.linkedContract ? mainContract.linkedContract.contractBegin : mainContract.contractBegin,
      endDateString: mainContract.leavingDate ? mainContract.leavingDate : mainContract.contractEnd
    },
  ]);

  if (ret === undefined) {
    throw new Error(`billingPeriod: 계약서 ${mainContract._id}와 청구대상월(${billingYear}-${billingMonth})이 겹치지 않습니다.`);
  }

  return ret;
}

/**
 * 직전 연장 계약이 있는 경우에 2개의 계약 기간을 합하지 않고
 * 청구대상기간에 속하는 각각의 계약 기간을 구한다.
 *
 * - 직전 연장 계약이 없거나 청구대상기간과 겹치지 않는다면 linkedContractBillingPeriod는 undefined가 된다.
 * - 직전 연장 계약이 mainContract와 겹치는 경우에 직전 계약 종료일을 보정한다.
 *
 * @param billingYear 청구년
 * @param billingMonth 청구월. 임대료, 관리비는 선불이기 때문에 8월 10일 청구일이 속하는 달인 8월 1일 ~ 31일이 청구대상기간이 된다.
 */
export function separatedBillingPeriod(billingYear: number, billingMonth: number, mainContract: ContractDoc) {
  // new Date(year, month, day, hour, minute, seconds)는 localtime(+09:00)이 적용된다.
  const billingStartTime = new Date(billingYear, billingMonth - 1, 1, 0, 0, 0).getTime();
  const billingEndTime = new Date(billingYear, billingMonth, 0, 23, 59, 59).getTime();

  const billingStartDateString = format(new Date(billingStartTime), 'yyyy-MM-dd');
  const billingEndDateString = format(new Date(billingEndTime), 'yyyy-MM-dd');

  //
  // 1. mainContract
  //
  const mainContractBillingPeriod = intersectionPeriod([
    {
      startDateString: billingStartDateString,
      endDateString: billingEndDateString
    },
    {
      startDateString: mainContract.contractBegin,
      endDateString: mainContract.leavingDate ? mainContract.leavingDate : mainContract.contractEnd
    },
  ]);

  if (mainContractBillingPeriod === undefined) {
    throw new Error(`계약서 ${mainContract._id}의 계약기간과 청구대상월(${billingYear}-${billingMonth})의 기간이 겹치지 않습니다.`);
  }

  // 직전 연장 계약이 없는 경우
  if (mainContract.linkedContract == null) {
    return {
      mainContractBillingPeriod,
      linkedContractBillingPeriod: undefined
    };
  }

  //
  // 2. linkedContract
  //

  try {
    // 보정: 직전 계약 종료 전에 새로운 계약이 시작한다면 새로운 계약 시작을 우선시한다.
    const { startDateString, endDateString } = differencePeriod({
      startDateString: mainContract.linkedContract.contractBegin,
      endDateString: mainContract.linkedContract.contractEnd
    }, {
      startDateString: mainContractBillingPeriod.startDateString,
      endDateString: mainContractBillingPeriod.endDateString
    });

    const linkedContractBillingPeriod = intersectionPeriod([
      {
        startDateString: billingStartDateString,
        endDateString: billingEndDateString
      },
      {
        startDateString,
        endDateString
      },
    ]);

    return {
      mainContractBillingPeriod,
      linkedContractBillingPeriod,
    };
  } catch (error) {
    throw new Error(`직전 계약이 있는 경우에 청구 기간을 나누는 경우에 예외 발생: ${error.message}`);
  }
}

/**
 * 여러 기간의 공통 기간을 응답한다.
 *
 * startDateString: '2021-08-01'
 * endDateString: '2021-08-31'
 */
export function intersectionPeriod(periods: { startDateString: string, endDateString: string }[]) {
  const startDateString = maxString(...periods.map(period => period.startDateString));
  const endDateString = minString(...periods.map(period => period.endDateString));

  if (endDateString < startDateString) {
    return undefined;
  }

  const startDateTimeString = `${startDateString}T00:00:00+09:00`;
  const endDateTimeString = `${endDateString}T23:59:59+09:00`;

  // 이용하기 편하도록 다양한 형태를 응답한다.
  return {
    startTime: new Date(startDateTimeString).getTime(),
    startDateString,
    startDateTimeString,
    endTime: new Date(endDateTimeString).getTime(),
    endDateString,
    endDateTimeString
  };
}

/**
 * l의 기간에서 r의 기간과 겹치는 부분을 제외한다.
 *
 * startDateString: '2021-08-01'
 * endDateString: '2021-08-31'
 */
export function differencePeriod(l: { startDateString: string, endDateString: string }, r: { startDateString: string, endDateString: string }) {
  const lStartDateTimeString = `${l.startDateString}T00:00:00+09:00`;
  const lEndDateTimeString = `${l.endDateString}T23:59:59+09:00`;
  const ll = {
    startTime: new Date(lStartDateTimeString).getTime(),
    startDateString: l.startDateString,
    startDateTimeString: lStartDateTimeString,
    endTime: new Date(lEndDateTimeString).getTime(),
    endDateString: l.endDateString,
    endDateTimeString: lEndDateTimeString
  };

  const rStartDateTimeString = `${r.startDateString}T00:00:00+09:00`;
  const rEndDateTimeString = `${r.endDateString}T23:59:59+09:00`;
  const rr = {
    startTime: new Date(rStartDateTimeString).getTime(),
    startDateString: r.startDateString,
    startDateTimeString: rStartDateTimeString,
    endTime: new Date(rEndDateTimeString).getTime(),
    endDateString: r.endDateString,
    endDateTimeString: rEndDateTimeString
  };

  // CASE 1. 겹치는 것이 없다.
  if ((r.endDateString < l.startDateString) ||
    (l.endDateString < r.startDateString)) {
    return ll;
  }

  // CASE 2. r이 l을 모두 포함한다.
  if ((r.startDateString <= l.startDateString) &&
    (l.endDateString <= r.endDateString)) {
    return undefined;
  }

  // CASE 3. r이 l에 속하면서 구간을 두 조각으로 만드는 경우
  if ((l.startDateString < r.startDateString) &&
    (r.endDateString < l.endDateString)) {
    throw new Error(`범위를 2조각으로 나누는 경우는 예외 상황입니다. R(${r.startDateString}~${r.endDateString})가 L(${l.startDateString} ~ ${l.endDateString})를 둘로 나누고 있습니다.`);
  }

  // CASE 4. r이 l의 뒷부분과 겹치는 경우
  if ((l.startDateString < r.startDateString) &&
    (r.startDateString <= l.endDateString)) {
    ll.endDateString = format(new Date(rr.startTime - 24 * 3600 * 1000), 'yyyy-MM-dd');
    ll.endDateTimeString = `${ll.endDateString}T23:59:59+09:00`;
    ll.endTime = new Date(ll.endDateTimeString).getTime();
    return ll;
  }

  // CASE 5. r이 l의 앞부분과 겹치는 경우
  if ((l.startDateString <= r.endDateString) &&
    (r.endDateString < l.endDateString)) {
    ll.startDateString = format(new Date(rr.endTime + 24 * 3600 * 1000), 'yyyy-MM-dd');
    ll.startDateTimeString = `${ll.startDateString}T00:00:00+09:00`;
    ll.startTime = new Date(ll.startDateTimeString).getTime();
    return ll;
  }

  throw new Error(`예기치 못한 CASE: ${l.startDateString}~${l.endDateString}, ${r.startDateString}~${r.endDateString}`);
}
