/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';

import { WHERE } from '../../schema/1/schema-common';
import { UserDoc, UserDocs } from '../../schema/3/schema';

import { UtilService } from '../1/util.service';

const collectionPath = 'user';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: UserDoc;
  ceoStatUsers: UserDocs = {};
  latestUserSubject = new BehaviorSubject<UserDoc>(null);
  latestCeoStatUserSubject = new BehaviorSubject<UserDocs>(null);
  subscription: Subscription;

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService
  ) {
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  observe(email: string) {
    console.log(`email = ${email}`);
    const docRef = this.db.doc<UserDoc>(`${collectionPath}/${email}`);

    this.subscription = docRef.valueChanges().subscribe(doc => {
      this.user = doc;

      this.latestUserSubject.next(doc);
    }, error => {
      this.utilService.toastrCatch(error, 'UserService:observe에서 에러 발생');
    });
  }

  observeCeoStatUser() {
    const queryFn: QueryFn = ref => {
      return ref.where('role', '==', 'ceostat');
    };

    const collection = this.db.collection<UserDoc>(collectionPath, queryFn);
    return collection.valueChanges();
  }

  observeCeoStatUsers() {
    const queryFn: QueryFn = ref => {
      return ref.where('role', '==', 'ceostat');
    };

    const collection = this.db.collection<UserDoc>(collectionPath, queryFn);
    collection.valueChanges().subscribe(docs => {
      const ceoStatUserDocs = {};

      for (const doc of docs) {
        ceoStatUserDocs[doc.email] = doc;
      }

      this.ceoStatUsers = ceoStatUserDocs;
      this.latestCeoStatUserSubject.next(this.ceoStatUsers);
    });
  }

  getCeoStatUserForRoom(wheres: WHERE[]) {
    const queryFn: QueryFn = ref => wheres.reduce(
      (query, where: WHERE) => query.where(where[0], where[1] as firestore.WhereFilterOp, where[2]), ref);

    const collection = this.db.collection<UserDoc>(collectionPath, queryFn);
    return collection.get();
  }
}
