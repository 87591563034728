/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { environment } from '../environments/environment';

import { SiteDocs } from './schema/3/schema';

import { roleMappings } from './core/1/string-map';
import { UtilService } from './core/1/util.service';
import { AddressService } from './core/1/address.service';
import { LocalConfigurationService } from './core/1/local-configuration.service';
import { VersionService } from './core/1/version.service';
import { SiteService } from './core/1/site.service';
import { trimOrganization } from './core/1/util';
import { RoomService } from './core/1/room.service';
import { AuthService } from './core/2/auth.service';
import { UserService } from './core/2/user.service';
import { LogService } from './core/3/log.service';
import { MessageService } from './core/3/message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isDev = false;
  public loggedIn = false;
  public email = '';
  public myVersion: string;
  public latestVersion: string;
  public showSitesFilter = false;
  public isPrintScreen = false;
  public currentNavItemName: string;

  public sites: {
    _id: string,
    siteName: string
  }[];
  public siteControl = new FormControl([]);
  private currentSites: string[] = [];

  private destroySignal = new Subject<boolean>();

  private subscription: Subscription = null;
  private versionSubscription: Subscription = null;
  private durationSubscription: Subscription = null;

  title = '발가락 빌링';
  navItems = [
    { name: '입주자 계약정보', route: '/contract', siteFilter: true },
    { name: 'divider' },
    { name: '월별 청구서 입력', route: '/monthly-billing', siteFilter: true },
    { name: '월별 청구서 검토', route: '/monthly-billing-flat', siteFilter: true },
    { name: '월별 청구서 확인', route: '/monthly-invoice' },
    { name: '수도/전기/가스 청구 검토', route: '/power-review' },
    { name: 'divider' },
    { name: '기타 청구 항목', route: '/monthly-billing-fee', siteFilter: true },
    { name: '정기 청구 항목', route: '/periodic-monthly-billing-fee', siteFilter: true },
    { name: 'divider' },
    { name: '온수 계측량 입력', route: '/measurement/온수', siteFilter: true },
    { name: '냉수 계측량 입력', route: '/measurement/냉수', siteFilter: true },
    { name: '전기 계측량 입력', route: '/measurement/전기', siteFilter: true },
    { name: '온수보일러 계측량 입력', route: '/measurement/온수보일러', siteFilter: true },
    { name: 'divider' },
    { name: '지점 전기/수도/가스 고지서 입력', route: '/site-measurement' },
    { name: '계측기 관리', route: '/measurement-device', siteFilter: true },
    { name: '정기 계측 검토', route: '/regular-measurement', siteFilter: true },
    // { name: 'divider' },
    // { name: '입금 내역 관리', route: '/deposit-detail', siteFilter: true },
    { name: 'divider' },
    { name: '지점 설정', route: '/site' },
    { name: '호실 설정', route: '/room' },
    // { name: 'divider' },
    // { name: '엑셀 업로드', route: '/excel-upload' },
    // { name: '정산용 런투유 | 스파이더 배송', route: '/delivery-fee' },
    // { name: '배송 일일 합계', route: '/delivery-summary' },
    // { name: 'divider' },
    // { name: '개발 테스트', route: '/test' }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private utilService: UtilService,
    private messageService: MessageService,
    private versionService: VersionService,
    private userService: UserService,
    private addressService: AddressService,
    private logService: LogService,
    private siteService: SiteService,
    private roomService: RoomService,
    private localConfigurationService: LocalConfigurationService,
  ) {
    this.isDev = environment.firebase.projectId !== 'toe-prod';

    // 모든 URL의 변화를 감시한다.
    const re = /^(\/[^/?]+[^?]*)(?:\?(.+))?$/;

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log(`urlAfterRedirects = ${event.urlAfterRedirects}`);

        const matches = re.exec(event.urlAfterRedirects);

        if (matches) {
          const prefix = matches[1]; // '/baemin-campaign-map',
          // 서로 시작 URL(prefix)이 같은지 비교한다.
          const foundNavItem = this.navItems.find(navItem => {
            if (navItem.route) {
              // event.urlAfterRedirects에서 한글이 인코딩된 값으로 받아지므로 prefix를 디코딩해야 비교할 수 있다.
              return navItem.route === decodeURI(prefix);
            }
            return false;
          });

          if (foundNavItem) {
            // 1. URL에 맞추어 타이틀을 수정한다.
            this.title = '발가락 빌링';
            this.currentNavItemName = foundNavItem.name;
            document.title = foundNavItem.name;

            // 2. UI 요소를 보여줄 지 말 지를 결정한다.
            this.showSitesFilter = foundNavItem.siteFilter === true;

            // 3. QueryString이 있다면 URL에서 상태 복원
            if (foundNavItem.siteFilter === true) {
              const sitesParams = this.route.snapshot.queryParamMap.get('sites');
              if (sitesParams) {
                this.currentSites = sitesParams.split(',');
                this.siteControl.setValue(this.currentSites);
                this.localConfigurationService.siteSubject.next(this.currentSites);
              }
            }

            if (prefix.includes('/monthly-invoice')) {
              const invoiceUrlMatches = re.exec(event.urlAfterRedirects);
              const documentNo = invoiceUrlMatches[2];
              if (documentNo) {
                this.isPrintScreen = true;
              }
            }

            // 4. QueryString 없이 이동하는 경우도 있기 때문에 상태로 URL 복원
            if (foundNavItem.siteFilter) {
              this.updateURL();
            }
          } else {
            console.error('메뉴에 없는 URL입니다.');
          }
        } else {
          console.error(`Unexpected URL: ${event.urlAfterRedirects}`);
        }
      }
    });
  }

  ngOnInit() {
    this.subscription = this.authService.observeLoggedIn().pipe(
      // 안정화되지 않은 상태인 null 을 제거하기 위함이다.
      filter(value => {
        return value === true || value === false;
      })
    ).subscribe(async value => {
      console.log(`@AppComponent loggedIn = ${value}`);
      this.loggedIn = value;
      if (value === true) {
        this.utilService.toastrInfo('로그인 성공!', null, 500);
        this.email = this.authService.user.email;

        let localAddresses = 'unknown';
        try {
          localAddresses = (await this.addressService.findLocalAddress()).join();
        } catch (err) {
          console.error('Fail to local IP address');
        }
        const publicAddress = await this.addressService.findPublicAddress();

        console.log(`localAddress = ${localAddresses}`);
        console.log(`publicAddress = ${publicAddress}`);
        // message에 기록을 남긴다.
        this.messageService.notificationLogin(this.email, {
          localIPs: localAddresses ? localAddresses : 'N/A',
          publicIP: publicAddress ? publicAddress : 'N/A'
        });
        this.logService.info(`로그인 성공. local = ${localAddresses}, remote = ${publicAddress}`, this.email);

        // 아래 3가지는 init-guard의 조건이기도 하다.
        // 여기에서 시작을 하고 init-guard에서는 데이터가 수신 완료되는 것을 기다린다.
        // TODO: 로그아웃 후에 기존의 데이터를 초기화하도록 확인한다.
        this.userService.observe(this.email);
        this.userService.latestUserSubject.subscribe(user => {
          if (user) {
            if (!['admin', 'operator', 'viewer'].includes(user.role)) {
              this.logService.withToastrError(`현재 사용자(${user.role})는 권한이 없습니다. 로그아웃합니다.`);
              this.logout();
            }
          }
        });
        this.userService.observeCeoStatUsers();
        this.siteService.observe();
        this.roomService.observe();
      }

      // subscribe로 변경하면서 아래의 문제가 저절로 해결된다.
      // refer: https://stackoverflow.com/questions/35105374/how-to-force-a-components-re-rendering-in-angular-2
      // LoginComponent가 붙을 경우에 별다른 일을 하지 않으니 AppComponent의 뷰가 갱신되지 않았다.
      // this.changeDetectorRef.detectChanges();
    });

    this.myVersion = this.versionService.myVersion;
    this.latestVersion = this.versionService.latestVersion;
    this.versionSubscription = this.versionService.latestVersionSubject.subscribe(lastesVersion => {
      this.latestVersion = lastesVersion;
    });

    this.siteService.latestSubject
      .pipe(takeUntil(this.destroySignal)).subscribe((sites: SiteDocs) => {
        this.sites = Object.values(sites).sort((a, b) => a.siteNo < b.siteNo ? -1 : 1).map(site => ({
          _id: site._id,
          siteName: trimOrganization(site.siteName)
        }));
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.versionSubscription) {
      this.versionSubscription.unsubscribe();
    }
    if (this.durationSubscription) {
      this.durationSubscription.unsubscribe();
    }

    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  get role() {
    return this.userService.user ? roleMappings[this.userService.user.role] : '...';
  }

  logout() {
    this.logService.info('로그아웃');

    this.authService.signOut().then(value => {
      this.messageService.notificationLogout();
      this.router.navigate(['/auth/login']);

      // 로그아웃을 하면 기존 상태도 초기화해야 한다.
      // 여러 서비스가 갖고 있는 상태를 초기화하기 위해서 일단 reload()한다.
      // TODO : 나중에는 상태를 정리한다 리로드 할 경우에 토스트 메시지가 사라지는 문제가 있다.
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  }

  reload() {
    window.location.reload();
  }

  /**
   * sites 값으로부터 URL을 만든다.
   */
  updateURL() {
    const currentPage = this.router.url.split('?')[0];

    const sites = this.siteControl.value as string[];

    // 동일 URL이라면 여러 번 반복해도 이벤트는 한 번만 발생한다.
    // currentPage는 한글이 인코딩된 값으로 받아지므로 디코딩해서 넘겨야 router.events.subscribe에사 두 번 인코딩된 값을 얻지 않는다.
    this.router.navigate([decodeURI(currentPage)], { queryParams: { sites: sites.join(',') } });
  }

  /**
   * valueChanges를 이용하면 목록 선택이 끝났을 때가 아니라 토글이 일어날 때마다 호출된다.
   * 토글이 닫혔을 때의 상태로 조회할 조건을 확정한다.
   */
  public siteControlOpenedChange(event: boolean) {
    // opened상태를 boolean 값을 파라미터로 받는다. (false: 닫힘 발생)
    if (!event) {
      const sites = this.siteControl.value;
      this.localConfigurationService.siteSubject.next(sites);
      this.updateURL();
    }
  }
}
