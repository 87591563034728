/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface BillingYearMonth {
  billingYear: string;
  billingMonth: string;
}
@Injectable({
  providedIn: 'root'
})
export class LocalConfigurationService {
  siteSubject = new BehaviorSubject<string[]>([]);
  billingYearMonthSubject: BehaviorSubject<BillingYearMonth>;

  constructor() {
    const now = new Date();
    this.billingYearMonthSubject = new BehaviorSubject<BillingYearMonth>({
      billingYear: String(now.getFullYear()),
      billingMonth: String(now.getMonth() + 1).padStart(2, '0')
    });
  }
}
