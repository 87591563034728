<span>청구월: </span>
<mat-select [formControl]="yearControl">
  <mat-option *ngFor="let year of years" [value]="year">{{year}}년</mat-option>
</mat-select>

<span>월: </span>
<mat-select [formControl]="monthControl">
  <mat-option *ngFor="let month of months" [value]="month">{{month}}월</mat-option>
</mat-select>

<button mat-stroked-button (click)="nextBillingYearMonth()">조회</button>