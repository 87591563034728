/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-notice',
  templateUrl: './dialog-notice.component.html',
  styleUrls: ['./dialog-notice.component.scss']
})
export class DialogNoticeComponent implements OnInit {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogNoticeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  onOk(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = undefined;
    }
  }

  ngOnInit() {
  }
}
