/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private toastr: ToastrService
  ) { }

  toastrInfo(message: string, title?: string, timeout = 10000) {
    const now = format(new Date(), `HH:mm:ss`);
    this.toastr.info(`[${now}] ${message}`, title, {
      timeOut: timeout
      // disableTimeOut: true
    });
  }

  toastrWarning(message: string, title?: string, timeout = 30000) {
    const now = format(new Date(), `HH:mm:ss`);
    this.toastr.warning(`[${now}] ${message}`, title, {
      timeOut: timeout
      // disableTimeOut: true
    });
  }

  toastrError(message: string, title?: string, timeout = 60000) {
    const now = format(new Date(), `HH:mm:ss`);
    console.error(message);
    this.toastr.error(`[${now}] ${message}`, title, {
      timeOut: timeout,
      disableTimeOut: true
    });
  }

  toastrCatch(error: Error | string, reason?: string) {
    const now = format(new Date(), `HH:mm:ss`);
    const msg = `${reason ? reason + ': ' : ''}${error}`;
    console.error(msg, error);

    this.toastr.error(`[${now}] ${msg}`, '예외 발생', {
      timeOut: 60000,
      disableTimeOut: true
    });
  }
}
