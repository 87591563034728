/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './auth-guard.service';
import { InitGuardService } from './init-guard.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'contract',
  },
  {
    path: 'contract',
    pathMatch: 'full',
    loadChildren: () => import('./pages/contract/contract.module').then(m => m.ContractModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'monthly-billing',
    pathMatch: 'full',
    loadChildren: () => import('./pages/monthly-billing/monthly-billing.module').then(m => m.MonthlyBillingModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'monthly-invoice',
    loadChildren: () => import('./pages/monthly-invoice/monthly-invoice.module').then(m => m.MonthlyInvoiceModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'monthly-billing-flat',
    pathMatch: 'full',
    loadChildren: () => import('./pages/monthly-billing-flat/monthly-billing-flat.module').then(m => m.MonthlyBillingFlatModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'delivery-fee',
    pathMatch: 'full',
    loadChildren: () => import('./pages/delivery-fee/delivery-fee.module').then(m => m.DeliveryFeeModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'delivery-summary',
    pathMatch: 'full',
    loadChildren: () => import('./pages/delivery-summary/delivery-summary.module').then(m => m.DeliverySummaryModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'measurement',
    pathMatch: 'prefix',
    loadChildren: () => import('./pages/measurement/measurement.module').then(m => m.MeasurementModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'site-measurement',
    pathMatch: 'prefix',
    loadChildren: () => import('./pages/site-measurement/site-measurement.module').then(m => m.SiteMeasurementModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'power-review',
    pathMatch: 'prefix',
    loadChildren: () => import('./pages/power-review/power-review.module').then(m => m.PowerReviewModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'excel-upload',
    pathMatch: 'full',
    loadChildren: () => import('./pages/excel-upload/excel-upload.module').then(m => m.ExcelUploadModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'site',
    pathMatch: 'full',
    loadChildren: () => import('./pages/site/site.module').then(m => m.SiteModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'room',
    pathMatch: 'full',
    loadChildren: () => import('./pages/room/room.module').then(m => m.RoomModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'measurement-device',
    pathMatch: 'full',
    loadChildren: () => import('./pages/measurement-device/measurement-device.module').then(m => m.MeasurementDeviceModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'regular-measurement',
    pathMatch: 'full',
    loadChildren: () => import('./pages/regular-measurement/regular-measurement.module').then(m => m.RegularMeasurementModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'test',
    pathMatch: 'full',
    loadChildren: () => import('./pages/test/test.module').then(m => m.TestModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'monthly-billing-fee',
    pathMatch: 'full',
    loadChildren: () => import('./pages/monthly-billing-fee/monthly-billing-fee.module').then(m => m.MonthlyBillingFeeModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'periodic-monthly-billing-fee',
    pathMatch: 'full',
    loadChildren: () => import('./pages/periodic-monthly-billing-fee/periodic-monthly-billing-fee.module').then(m => m.PeriodicMonthlyBillingFeeModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: 'deposit-detail',
    pathMatch: 'full',
    loadChildren: () => import('./pages/deposit-detail/deposit-detail.module').then(m => m.DepositDetailModule),
    canActivate: [AuthGuardService, InitGuardService],
  },
  {
    path: '**',
    redirectTo: ''
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
