/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { SearchTermService, SearchDate } from '../../core/1/search-term.service';
import { UtilService } from '../../core/1/util.service';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],

})
export class DateSelectorComponent implements OnInit  {
  dateSubscription: Subscription;

  // 데이터 조회를 위한 변수가 아니라 ui 표시용 (input value) 변수이다.
  searchDate: SearchDate;
  @Input() defaultTerm: string;
  @Input() maxDuration = 93;

  constructor(
    private searchTerm: SearchTermService,
    private dateAdapter: DateAdapter<any>,
    private utilService: UtilService
  ) {
    this.dateAdapter.setLocale('toe-kr');
  }

  ngOnInit() {
    this.setTerms(this.defaultTerm);
  }

  onChangeStartDate(event: MatDatepickerInputEvent<any>) {
    const newStartDate = event.value._d;

    if (newStartDate > this.searchDate.endDate) {
      this.utilService.toastrWarning('시작일이 종료일을 넘을 수 없습니다.', '[기간 확인]', 3000);
      this.searchDate.endDate = newStartDate;
    }

    const diffDay = (this.searchDate.endDate.getTime() - newStartDate.getTime()) / (1000 * 60 * 60 * 24);
    if (diffDay >= this.maxDuration) {
      this.utilService.toastrWarning(`최대 ${this.maxDuration}일까지 조회가능합니다.`, '[기간 확인]', 3000);

      // Date도 객체이기 때문에 date = this.searchDate.startDate는 참조복사가 일어난다.
      // date.setDate()를 하게 되면 해당 주소의 날짜 값(value)이 바뀌므로 this.searchDate.startDate에도 변화가 일어나는 문제점이 있어서 값을 사용한 새 Date객체를 만든다.
      const newEndDate = new Date(newStartDate);
      newEndDate.setDate(newEndDate.getDate() + this.maxDuration - 1);
      this.searchDate.endDate = newEndDate;
    }

    this.searchDate.startDate = newStartDate;
    this.searchTerm.searchDateSubject.next(this.searchDate);

  }

  onChangeEndDate(event: MatDatepickerInputEvent<any>) {
    const newEndDate = event.value._d;

    if ( this.searchDate.startDate > newEndDate) {
      this.utilService.toastrWarning('시작일이 종료일을 넘을 수 없습니다.', '[기간 확인]', 3000);
      this.searchDate.startDate = newEndDate;
    }

    const diffDay = (newEndDate - this.searchDate.startDate.getTime()) / (1000 * 60 * 60 * 24);
    if (diffDay >= this.maxDuration) {
      this.utilService.toastrWarning(`최대 ${this.maxDuration}일까지 조회가능합니다.`, '[기간 확인]', 3000);

      // Date도 객체이기 때문에 date = this.searchDate.endDate 참조복사가 일어난다.
      // date.setDate()를 하게 되면 해당 주소의 날짜 값(value)이 바뀌므로 this.searchDate.endDate 변화가 일어나는 문제점이 있어서 값을 사용한 새 Date객체를 만든다.
      const newStartDate = new Date(newEndDate);
      newStartDate.setDate(newEndDate.getDate() - (this.maxDuration - 1));
      this.searchDate.startDate = newStartDate;
    }

    this.searchDate.endDate = newEndDate;
    this.searchTerm.searchDateSubject.next(this.searchDate);
  }

  /**
   * 오늘을 기준으로 일자 범위를 지정한다.
   *
   * @param diffDay 0: 오늘,-1: 어제, 1: 내일
   */
  setRelativeDay(diffDay: number) {
    const today = new Date();

    // diffDay 이전 - 연도도 자동으로 바뀐다.
    const startDate = new Date(today);
    startDate.setDate(startDate.getDate() + diffDay);
    // 종료일은 오늘
    const endDate = today;

    this.searchDate = { startDate, endDate };
    this.searchTerm.searchDateSubject.next(this.searchDate);
  }

  /**
   * 이번 달 기준으로 달의 범위를 지정한다.
   *
   * @param diffMonth 0: 이번달, -1: 지난 달, 1: 다음달
   */
  setRelativeMonth(diffMonth: number) {

    const today = new Date();
    // 이번달 1일
    // Safari는 '2020-6-01'을 거부
    const startDate = new Date(`${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-01`);
    // 이번달 + diffMonth 1일로 변경
    startDate.setMonth(startDate.getMonth() + diffMonth);

    const endDate = new Date(startDate);
    // 이번달 + diffMonth + 1 1일로 변경
    endDate.setMonth(endDate.getMonth() + 1);
    // 말일은 1일의 전날이다.
    endDate.setDate(endDate.getDate() - 1);

    this.searchDate = { startDate, endDate };
    this.searchTerm.searchDateSubject.next(this.searchDate);
  }

  setTerms(value) {
    switch (value) {
      case 'lastMonth':
        this.setRelativeMonth(-1);
        break;

      case 'thisMonth':
        this.setRelativeMonth(0);
        break;
    }
  }
}
