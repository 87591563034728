/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BillingCategoryMap } from '../../schema/1/schema-billing';

export const roleMappings = {
  admin: '관리자',
  operator: '접수자',
  ceo: '사장님',
};

export const unifiedOrderStatusMappings = {
  0: '이상상태',
  10: '신규주문',
  15: '업소접수',
  17: '배차대기',
  20: '주문접수',
  30: '조리시작',
  40: '조리완료',
  50: '포장완료',
  60: '배송중',
  70: '배송완료',
  80: '취소',
  90: '삭제'
};

export const unifiedOrderVendorMappings = {
  baemin: '배민',
  foodfly: '푸플',
  yogiyo: '요기요',
  ubereats: '우버이츠',
  ddingdong: '띵동',
  coupangeats: '쿠팡이츠',
  shuttle: '셔틀',
  ghostkitchen: '고스트키친',
  other: '기타',
};

export const unifiedOrderChannelMappings = {
  app: '앱',
  tel: '전화',
  face: '직접방문'
};

export const unifiedOrderDeliveryTypeMappings = {
  DELIVERY: '배달',
  TAKEOUT: '테이크아웃',
  FOODFLY: '푸드플라이',
  BAERA: '배라',
  DDINGDONG: '띵동',
  UBER: '우버이츠',
  COUPANG: '쿠팡이츠',
  SHUTTLE: '셔틀',
  HERE: '매장식사'
};

export const unifiedOrderCreatedByMappings = {
  manual: '직접입력'
};

export const baeminAppListPaymentMethodMappings = {
  1: '만나서현금',
  2: '만나서카드',
};

export const baeminAppPaymentGroupMappings = {
  1: '선불',
  2: '후불'
};

export const baeminAppDetailPaymentMethodMappings = {
  1: '카드',
  2: '휴대폰결제',
  5: '만나서현금',
  6: '만나서카드',
  12: '페이코',
  13: '배민페이 계좌이체',
  14: '배민페이',
  15: '토스',
  16: '네이버페이',
  17: '카카오페이',
  30: '네이버페이2', // T0G20000076D 16과 구별하기 위해서 네이버페이2라고 했다.
  99: 'OK캐시백' // 2019-07-19. 100%할인 받은 경우일 수도 있다.
};

export const baeminCeoStatusMappings = {
  OPENED: '신규',
  ACCEPTED: '주문접수',
  CLOSED: '배송완료',
  CANCELLED: '취소'
};

export const baeminCeoCategoryCodeMappings = {
  1: '치킨',
  2: '중식',
  3: '피자',
  4: '족발,보쌈',
  5: '야식',
  6: '찜,탕',
  7: '패스트푸드',
  9: '도시락',
  10: '돈까스,회,일식',
  32: '한식',
  33: '분식',
  34: '카페,디저트'
};

export const baeminCeoPurchaseTypeMappings = {
  BARO: '선불',
  MEET: '후불'
};

export const vroongStatusMappings = {
  SUBMITTED: '배차대기',
  ASSIGNED: '픽업중',
  PICKED_UP: '배송중',
  DELIVERED: '배송완료',
  CANCELED: '취소',
};

export const vroongPayMethodMappings = {
  PREPAID: '선불',
  AT_DEST_CARD: '후불카드',
  AT_DEST_CASH: '후불현금'
};

export const foodflyAcceptStatusMappings = {
  0: '신규',
  3: '주문접수',
  5: '조리완료',
  98: '취소',
  99: '주문거절'
};

export const foodflySendStatusMappings = {
  1: '배차대기',
  2: '배차완료',
  3: '업장도착',
  4: '픽업',
  5: '배달완료',
  6: '취소'
};

export const deliveryVendorMappings = {
  ghostrider: '고스트라이더',
  run2u: '런투유',
  spidor: '스파이더',
  ghokirun: '고키런'
};

// tslint:disable: object-literal-key-quotes
export const subCategoryMappings: {
  [key in keyof BillingCategoryMap]: BillingCategoryMap[key][]
} = {
  '임대,관리비': ['임대료', '관리비', '장기계약할인', '렌트프리'] as BillingCategoryMap['임대,관리비'][],
  '전력비,수도광열비': ['개별전기', '공용전기', '전력기금', '수도요금'] as BillingCategoryMap['전력비,수도광열비'][],
  '배달대행비': ['런투유', '고키런', '부릉', '고스트라이더', '스파이더', '차감정산'] as BillingCategoryMap['배달대행비'][],
  '기타': ['일회용기', '통신비', '추가 및 증설', '냉장고추가사용', '기타 관리비', '로열티', '주문접수', '세무대행'] as BillingCategoryMap['기타'][],
  '프로모션 혜택': ['울트라콜 광고비 지원'] as BillingCategoryMap['프로모션 혜택'][],
  '프로모션 외 혜택': ['울트라콜 광고비 지원'] as BillingCategoryMap['프로모션 외 혜택'][],
  '미납, 납부지연가산금': ['미납', '지연가산금'] as BillingCategoryMap['미납, 납부지연가산금'][],
};
