/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

import { LocalConfigurationService, BillingYearMonth } from '../../core/1/local-configuration.service';
@Component({
  selector: 'app-billing-month-filter',
  templateUrl: './billing-month-filter.component.html',
  styleUrls: ['./billing-month-filter.component.scss']
})
export class BillingMonthFilterComponent implements OnInit, OnDestroy {
  public years: string[];
  public yearControl: FormControl;
  public months: string[];
  public monthControl: FormControl;

  private destroySignal: Subject<void> = new Subject();

  constructor(
    private localConfigurationService: LocalConfigurationService
  ) {
    // i는 index이므로 0부터 하나씩 증가한다.
    this.years = Array(5).fill(2020).map((x, i) => String(x + i));                    // 2020 ~ 2024
    this.months = Array(12).fill(1).map((x, i) => String(x + i).padStart(2, '0'));    // 01 ~ 12
    this.yearControl = new FormControl(String(new Date().getFullYear()));
    this.monthControl = new FormControl(String(new Date().getMonth() + 1));
  }

  ngOnInit(): void {
    this.localConfigurationService.billingYearMonthSubject
      .pipe(takeUntil(this.destroySignal))
      .subscribe((month: BillingYearMonth) => {
        this.yearControl.setValue(month.billingYear, { emitEvent: false });
        this.monthControl.setValue(month.billingMonth, { emitEvent: false });
      });
  }

  ngOnDestroy() {
    this.destroySignal.next();
    this.destroySignal.unsubscribe();
  }

  nextBillingYearMonth() {
    const billingYearMonth = {
      billingYear: this.yearControl.value,
      billingMonth: this.monthControl.value
    };
    this.localConfigurationService.billingYearMonthSubject.next(billingYearMonth);
  }
}
