<div class="calendar-area">
  <span>조회 기간 : </span>
          
  <input [matDatepicker]="startDatePicker" (dateChange)="onChangeStartDate($event)" [value]="searchDate.startDate">
  <mat-datepicker-toggle [for]="startDatePicker"></mat-datepicker-toggle>
  <mat-datepicker #startDatePicker></mat-datepicker>
  ~&nbsp;
  <input [matDatepicker]="endDatePicker" (dateChange)="onChangeEndDate($event)" [value]="searchDate.endDate">
  <mat-datepicker-toggle [for]="endDatePicker"></mat-datepicker-toggle>
  <mat-datepicker #endDatePicker></mat-datepicker>

  <!--  input으로 날짜를 수정하더라도 button-toggle-group의 값이 바뀌지 않기 때문에 오늘->input수정 ->오늘 순서로 클릭하게되면 change이벤트가 발생하지 않아 click이벤트로 변경하였다.
  토글 그룹에서의 click 이벤트는 value를 전달하지 않아 각 버튼에 따로 이벤트를 달았다.-->
  <mat-button-toggle-group name="terms" class="term-button">
    <mat-button-toggle value="lastMonth" (click)="setTerms('lastMonth')">지난달</mat-button-toggle>
    <mat-button-toggle value="thisMonth" (click)="setTerms('thisMonth')">이번달</mat-button-toggle>
  </mat-button-toggle-group>    
</div>