/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { format, parseISO } from 'date-fns';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { Message, MessageBodyMap } from '../../schema/2/schema-message';

import { instanceId } from '../1/common';
import { UserService } from '../2/user.service';

import { environment } from '../../../environments/environment';

const collectionPath = 'message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(
    private db: AngularFirestore,
    private userService: UserService
  ) {

  }

  observeMessage(openHours: number, openMinutes: number, atDate = 0, orderBy: 'asc' | 'desc' = 'asc') {
    const now = new Date();
    const nowHours = now.getHours();
    const nowMinutes = now.getMinutes();

    // 현재 시각이 오픈 이전이라면 이전 날짜에 대한 주문부터 가져온다.
    if (openHours * 60 + openMinutes > nowHours * 60 + nowMinutes) {
      atDate -= 1;
    }

    const openHHMM = String(openHours).padStart(2, '0') + ':' + String(openMinutes).padStart(2, '0');

    // safari가 +0900 형식을 지원하지 않아서 번잡하게 2번의 변환을 거친다.
    const atDateString = format(new Date(now.getTime() + atDate * 24 * 3600 * 1000), `yyyy-MM-dd'T'${openHHMM}:00+0900`);
    const atDateTime = parseISO(atDateString);

    console.log(`${this.constructor.name}::observeMessage from ${atDateString}`);
    const queryFn: QueryFn = ref => {
      const query1 = ref.orderBy('_timeCreate', orderBy);
      const query = orderBy === 'asc' ?
        query1.startAt(firestore.Timestamp.fromDate(atDateTime)) :
        query1.endAt(firestore.Timestamp.fromDate(atDateTime));
      return query;
    };

    const messageCollection = this.db.collection<Message<any, any>>(collectionPath, queryFn);

    // 디버깅용
    if (environment.production === false) {
      messageCollection.stateChanges().pipe(
        map(actions => actions.map(action => {
          return { _type: action.type, ...action.payload.doc.data() };
        }))
      ).subscribe(orders => {
        for (const order of orders) {
          // console.log(`[${scooter.vendor}] ${scooter.no} '${scooter._type}'`);
        }
      });
    }

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    const observable = messageCollection.valueChanges();

    return observable;
  }

  private async notification<N extends keyof MessageBodyMap['notification']>(
    name: N,
    body: MessageBodyMap['notification'][N],
    email?: string
  ) {
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;

    const cmd: Message<'notification', N> = {
      _id: docId,
      organization: this.userService.user.organization,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      channel: 'message',
      from: {
        class: 'billing',
        instanceNo: instanceId,
        account: email ? email : this.userService.user.email
      },
      // to,
      type: 'notification',
      name,
      body,
    };

    return await this.db.doc<Message<'notification', N>>(docRef).set(cmd);
  }

  async notificationLogin(email: string, body: any = null) {
    return await this.notification('login', body, email);
  }

  async notificationLogout() {
    return await this.notification('logout', null);
  }
}
