/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { format, parseISO } from 'date-fns';
import get from 'lodash-es/get';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { LogLevel } from '../../schema/1/schema-common';
import { Log } from '../../schema/2/schema-message';
import { LogOrderDoc } from '../../schema/3/schema';

import { instanceId } from '../1/common';
import { UtilService } from '../1/util.service';
import { AddressService } from '../1/address.service';
import { UserService } from '../2/user.service';

import { environment } from '../../../environments/environment';

const collectionPath = 'logBilling';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(
    private db: AngularFirestore,
    private utilService: UtilService,
    private userService: UserService,
    private addressService: AddressService,
  ) {
    console.log(`log instance ID = ${instanceId}`);
  }

  observeLog(openHours: number, openMinutes: number, atDate = 0, orderBy: 'asc' | 'desc' = 'asc') {
    const now = new Date();
    const nowHours = now.getHours();
    const nowMinutes = now.getMinutes();

    // 현재 시각이 오픈 이전이라면 이전 날짜에 대한 주문부터 가져온다.
    if (openHours * 60 + openMinutes > nowHours * 60 + nowMinutes) {
      atDate -= 1;
    }

    const openHHMM = String(openHours).padStart(2, '0') + ':' + String(openMinutes).padStart(2, '0');

    // safari가 +0900 형식을 지원하지 않아서 번잡하게 2번의 변환을 거친다.
    const atDateString = format(new Date(now.getTime() + atDate * 24 * 3600 * 1000), `yyyy-MM-dd'T'${openHHMM}:00+0900`);
    const atDateTime = parseISO(atDateString);

    console.log(`${this.constructor.name}::observeLog from ${atDateString}`);
    const queryFn: QueryFn = ref => {
      const query1 = ref.orderBy('_timeCreate', orderBy);
      const query = orderBy === 'asc' ?
        query1.startAt(firestore.Timestamp.fromDate(atDateTime)) :
        query1.endAt(firestore.Timestamp.fromDate(atDateTime));
      return query;
    };

    const messageCollection = this.db.collection<Log>(collectionPath, queryFn);

    // 디버깅용
    if (environment.production === false) {
      messageCollection.stateChanges().pipe(
        map(actions => actions.map(action => {
          return { _type: action.type, ...action.payload.doc.data() };
        }))
      ).subscribe(logs => {
        for (const log of logs) {
          // console.log(`[${scooter.vendor}] ${scooter.no} '${scooter._type}'`);
        }
      });
    }

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    const observable = messageCollection.valueChanges();

    return observable;
  }


  /**
   * @param email: 아직 userService에서 정보를 못 찾아오는 단계에세 사용한다.
   */
  private async send(level: LogLevel, message: string, email?: string) {
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;

    const doc: Log = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      from: {
        class: 'billing',
        instanceNo: instanceId,
        account: email ? email : get(this.userService, ['user', 'email'], ''),
      },
      level,
      message
    };

    return await this.db.doc<Log>(docRef).set(doc);
  }

  public async debug(message: string, email?: string) {
    await this.send('debug', message, email);
  }
  public async info(message: string, email?: string) {
    await this.send('info', message, email);
  }
  public async warn(message: string, email?: string) {
    await this.send('warn', message, email);
  }
  public async error(message: string, email?: string) {
    await this.send('error', message, email);
  }

  public async withToastrError(message: string, title?: string, timeout?: number) {
    this.utilService.toastrError(message, title, timeout);
    await this.send('error', `${title ? title + ': ' : ''} ${message}`);
  }

  public async withToastrCatch(error: Error | string, reason?: string) {
    this.utilService.toastrCatch(error, reason);
    const msg = `${reason ? reason + ': ' : ''}${error}`;
    await this.send('error', msg);
  }

  /**
   * @param email: 아직 userService에서 정보를 못 찾아오는 단계에세 사용한다.
   */
  public async logOrder(order: { _id: string; site: string; room: string; }, message: string, level?: LogLevel, createdBy?: 'manual') {
    const logOrdercollectionPath = 'logOrder';
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(logOrdercollectionPath).doc();
    const docId = docRef.id;

    if (order._id === undefined) {
      this.withToastrError(`logOrder의 다음 message에 대한 orderId가 없다. message=\n${message}`);
    }

    const doc: LogOrderDoc = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,

      organization: 'ghostkitchen',
      site: order.site ?? 'ERROR',
      room: order.room ?? 'ERROR',
      orderId: order._id ?? 'ERROR',

      instanceType: 'billing',
      instanceNo: instanceId,
      account: this.userService?.user.email ?? '',

      publicIP: this.addressService.publicAddress ?? '',

      level: level ?? 'info',
      message
    };

    if (createdBy) {
      doc.createdBy = createdBy;
    }

    try {
      return await this.db.doc<LogOrderDoc>(docRef).set(doc);
    } catch (error) {
      this.withToastrCatch(error);
    }
  }

  /**
   * logRoom은 logOrder와 같은 컬렉션을 사용한다.
   */
  public logRoom(roomKey: string, message: string, level?: LogLevel) {
    const matches = roomKey.match(/^(gk-.+)-\d{2}$/);
    if (matches) {
      const site = matches[1];
      return this.logOrder({ _id: 'room', site, room: roomKey }, message, level);
    } else {
      return this.send('error', `${roomKey}에 대한 site를 유도할 수 없어서 logRoom 실패`);
    }
  }

  public async logRoomMemo(roomKey: string, message: string, level?: LogLevel) {
    const matches = roomKey.match(/^(gk-.+)-\d{2}$/);
    if (matches) {
      const site = matches[1];
      return this.logOrder({ _id: 'room', site, room: roomKey }, message, level, 'manual');
    } else {
      return this.send('error', `${roomKey}에 대한 site를 유도할 수 없어서 logRoom 실패`);
    }
  }

  public logRoomWithToastWarn(roomKey: string, message: string, title?: string, timeout?: number) {
    this.utilService.toastrWarning(message, title, timeout);
    return this.logRoom(roomKey, `${title ? title + ': ' : ''} ${message}`, 'warn');
  }

  public logRoomWithToastrError(roomKey: string, message: string, title?: string, timeout?: number) {
    this.utilService.toastrError(message, title, timeout);
    return this.logRoom(roomKey, `${title ? title + ': ' : ''} ${message}`, 'error');
  }

  public logRoomWithToastrCatch(roomKey: string, error: Error | string, reason?: string) {
    this.utilService.toastrCatch(error, reason);
    const msg = `${reason ? reason + ': ' : ''}${error}`;
    return this.logRoom(roomKey, msg, 'error');
  }
}
