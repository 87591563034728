/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SafePipe } from './safe.pipe';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';

import { DialogNoticeComponent } from './dialog-notice/dialog-notice.component';
import { DialogDeleteComponent } from './dialog-delete/dialog-delete.component';
import { DialogSpinnerComponent } from './dialog-spinner/dialog-spinner.component';
import { DialogLoginComponent } from './dialog-login/dialog-login.component';
import { BillingMonthFilterComponent } from './billing-month-filter/billing-month-filter.component';
import { DateSelectorComponent } from './date-selector/date-selector.component';
import { DateTimeRangePickerComponent } from './date-time-range-picker/date-time-range-picker.component';
import { DateTimeSelectorComponent } from './date-time-selector/date-time-selector.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    MatTooltipModule,
    OverlayModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule
  ],
  declarations: [
    SafePipe,
    DialogNoticeComponent,
    DialogSpinnerComponent,
    DialogLoginComponent,
    DialogDeleteComponent,
    BillingMonthFilterComponent,
    DateSelectorComponent,
    DateTimeSelectorComponent,
    DateTimeRangePickerComponent
  ],
  exports: [
    SafePipe,
    BillingMonthFilterComponent,
    DateSelectorComponent,
    DateTimeSelectorComponent,
    DateTimeRangePickerComponent
  ],
})
export class SharedModule { }
