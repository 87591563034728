<mat-toolbar color="primary"  *ngIf="!isPrintScreen">
  <button mat-icon-button style="z-index: 10000; background-color: #3f51b5;" (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>

  <h1><span class="hideUnder768Width">{{title}} {{this.currentNavItemName ? '- ' : ''}}</span> {{this.currentNavItemName}}</h1>

  <div class="siteSelector" *ngIf="showSitesFilter">
    <mat-form-field floatLabel="always">
      <mat-label>지점 선택</mat-label>
      <mat-select panelClass="longSelectPanel" [formControl]="siteControl" multiple (openedChange)="siteControlOpenedChange($event)" placeholder="모든 지점">
        <mat-option *ngFor="let site of sites" [value]="site._id">{{site.siteName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="right">
    <button *ngIf="myVersion != latestVersion" mat-raised-button color="accent" (click)="reload()">업데이트</button>

    <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>help</mat-icon></button>
    <mat-menu class="menu-buttons" #menu="matMenu">
      <span mat-menu-item *ngIf="loggedIn == true">{{ email }} [{{ role }}]</span>
      <button mat-menu-item *ngIf="loggedIn == true" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>로그아웃</span>
      </button>
      <mat-divider></mat-divider>
      <span mat-menu-item class="version">현재 버전:{{myVersion}}</span>
      <span mat-menu-item class="version" *ngIf="myVersion != latestVersion">최신 버전:{{latestVersion}}</span>
      <span mat-menu-item class="version" *ngIf="isDev">개발 환경: toe-dev</span>
    </mat-menu>
  </div>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" disableClose>
    <mat-nav-list>
      <ng-container *ngFor="let navItem of navItems">
        <div *ngIf="navItem.name !== 'divider'">
          <a *ngIf="navItem.route != '/auth/signup' || loggedIn == false"
            mat-list-item
            routerLinkActive="sidenav-item-selected"
            #routerLinkActiveInstance="routerLinkActive"
            [attr.tabindex]="routerLinkActiveInstance.isActive ? 0 : -1"
            [routerLink]="[navItem.route]"
            >
            {{navItem.name}}
          </a>
        </div>
        <mat-divider *ngIf="navItem.name === 'divider'"></mat-divider>
      </ng-container>
      <mat-divider></mat-divider>
      <a mat-list-item href="javascript:window.location.reload();">리로드</a>
    </mat-nav-list>
  </mat-sidenav>

  <div class="container">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
