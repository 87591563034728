/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { RoomDoc, RoomDocs } from '../../schema/3/schema';

const collectionPath = 'room';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class RoomService {
  rooms: RoomDocs = {};
  latestSubject = new BehaviorSubject<RoomDocs>({});
  subscription: Subscription;

  constructor(
    private db: AngularFirestore,
  ) {
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  observe() {
    const queryFn: QueryFn = ref => {
      return ref.where('organization', '==', 'ghostkitchen');
    };

    const collection = this.db.collection<RoomDoc>(collectionPath, queryFn);
    collection.valueChanges().subscribe(docs => {
      const roomDocs = {};

      for (const doc of docs) {
        roomDocs[doc._id] = doc;
      }

      this.rooms = roomDocs;

      this.latestSubject.next(this.rooms);
    });
  }

  updateRoomField(roomKey: string, fieldPath: string, value: any) {
    return this.db.doc(`${collectionPath}/${roomKey}`).update({
      [fieldPath] : value
    });
  }
}
